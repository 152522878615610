const { navigateUserBasedOnRole } = useInterAppNavigationService();
const { constructMicroAppUrl, getRoleBaseDashboardPath } = useNavigation();

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.server) {
    let isAuthenticated = false;

    const event = useRequestEvent();

    if (event?.context.session?.extUserId) {
      isAuthenticated = true;
    }

    // If user is not logged in or is not a parent, redirect to the appropriate page
    if (!isAuthenticated) {
      const redirectUrl = constructMicroAppUrl(['home'], useRuntimeConfig().public.microapps['urlContracts'].onboarding);
      if (redirectUrl && to.path !== redirectUrl) {
        return navigateTo(redirectUrl, {
          external: true
        });
      }
    } else if (event?.context.session?.role !== 'parent') {
      navigateUserBasedOnRole(event?.context.session?.role);
    }
    return;
  }
});